import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/config/element/element.cfg'
import '@/config/nprogress/nprogress.cfg'


import VueQuillEditor from 'vue-quill-editor'
// 引入相关css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);


Vue.config.productionTip = false
    //根据环境判断是否需要mock
if (process.env.VUE_APP_MOCK.toLowerCase() === 'true') {
    require('@/mock')
}
Vue.use(ElementUI)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
