<template>
  <div class="login">
    <div class="login-bg"></div>
    <!--  login-->
    <div class="login-wrap" @keyup.enter="doLogin">
      <p class="login-text">用户登录</p>
      <div class="login-Input">
        <el-input
          autofocus
          prefix-icon="el-icon-user"
          placeholder="请输入账号"
          v-model="user.account"
          size="mini"
          clearable
        >
        </el-input>
        <!-- <br>
      <br> -->
        <el-input
          prefix-icon="el-icon-lock"
          placeholder="请输入密码"
          v-model="user.password"
          size="mini"
          show-password
        >
        </el-input>
        <!-- <br>
      <br> -->
        <el-button
          type="primary"
          size="mini"
          style="width: 50%"
          :loading="loading"
          @click="doLogin"
          >登录</el-button
        >
      </div>
    </div>
    <Copy />
  </div>
</template>

<script>
import Copy from "@/components/layouts/Copy";
import { login } from "../api/login";

export default {
  name: "Login",
  components: { Copy },
  data() {
    return {
      user: { account: "", password: "" },
      loading: false,
    };
  },
  created() {},
  methods: {
    //登录
    async doLogin() {
      if (!this.check()) {
        return false;
      }
      this.loading = true;
      const resp = await login(this.user);
      if (resp.code === 0) {
        this.$message.success("登录成功");
        window.sessionStorage.setItem("nickName", resp.data.nickName);
        window.sessionStorage.setItem("authorization", resp.data.token);
        this.loading = false;
        await this.$router.push("/admin");
      } else {
        this.$message.error(resp.msg);
        this.loading = false;

      }
      this.loading = false;
    },
    //验证
    check() {
      const user = this.user;
      if (user.account === "") {
        this.$message.warning("请输入账号");
        return false;
      }
      if (user.password === "") {
        this.$message.warning("请输入密码");
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.login-bg {
  //background-color: #0066A8;
  background: #fff url(../assets/login_back.jpg) no-repeat;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.login-wrap {
  width: 450px;
  height: 300px;
  // background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .login-text {
    text-align: center;
    font-size: 26px;
    font-weight: 300;
  }

  .login-Input {
    height:60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .el-input {
      width: 80% !important;
    }
     ::v-deep .el-input--mini .el-input__inner {
        height: 38px !important;
        line-height: 38px !important;
      }
      .el-button {
        height: 38px !important;
      }
  }
}
</style>
