import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'

import {
    Loading,
    MessageBox,
    Message,
    Notification,
    Input,
    Button,
    Container,
    Aside,
    Menu,
    MenuItem,
    Submenu,
    Header,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Main,
    Row,
    Col,
    Image,
    Card,
    Drawer,
    Form,
    FormItem,
    Table,
    TableColumn,
    Pagination,
    Dialog
} from 'element-ui';

/**
 * elementUI的按需引入配置
 */
Vue.use(Input);
Vue.use(Button);
Vue.use(Container);
Vue.use(Aside);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Header);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Main);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Card);
Vue.use(Drawer);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
