import NProgress from "nprogress";
import router from "../../router";
import Vue from "vue";
import 'nprogress/nprogress.css'

NProgress.configure({
    easing: 'ease',  // 动画方式
    speed: 500,  // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})
router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});
router.afterEach(() => {
    NProgress.done()
})
Vue.prototype.$NProgress = NProgress;