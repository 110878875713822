import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/login',
        component: Login
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin',
        name: 'Admin',
        redirect: "/admin/user",
        component: () =>
            import ('../views/admin/Admin'),
        children: [

            {
                path: 'user',
                name: 'User',
                component: () =>
                    import ('../views/admin/User'),
            },
            {
                path: 'manager',
                name: 'Manager',
                component: () =>
                    import ('../views/admin/Manager'),
            },
            {
                path: 'invitation',
                name: 'Invitation',
                component: () =>
                    import ('../views/admin/Invitation'),
            },
            {
                path: 'dict',
                name: 'Dict',
                component: () =>
                    import ('../views/admin/Dict'),
            },
            {
                path: 'background',
                name: 'Background',
                component: () =>
                    import ('../views/admin/Background'),
            },
            {
                path: 'phone',
                name: 'phone',
                component: () =>
                    import ('../views/admin/Phone'),
            },
            {
                path: 'teachText',
                name: 'teachText',
                component: () =>
                    import ('../views/admin/TeachText'),
            },
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () =>
            import ('../views/404')
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: '/'+ process.env.BASE_URL  ,
    routes
})

// 挂载 路由导航首位
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next()
    const tokenStr = window.sessionStorage.getItem('authorization')
    if (!tokenStr) return next('/login')
        // console.log(!tokenStr)
    next()
        //获取token
});

export default router
