<template>
  <!--版权信息-->
  <div class="footer">
    <p style="font-size: 12px;color: #ececec;text-align: center;position: fixed;bottom: 0;left: 0;right: 0;">
      Copyright © 2020 - 2021 scsoul.top All Rights Reserved. | xxxxx
    </p>
  </div>
</template>

<script>
export default {
  name: "Copy"
}
</script>

<style scoped>

</style>
